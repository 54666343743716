.page-container {
  display: flex;
  justify-content: space-around;
  padding: 40px 50px;
  min-height: 70vh;
  align-items: center;
  color: #fff;
}

@media (max-width: 767px) {
  .page-container {
    flex-direction: column;
  }
}

.content {
  padding-right: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.form-container {
  max-width: 400px;
  background-color: #1e1e1e; /* Background color black */
  padding: 60px;
  color: #fff; /* Text color white */
  font-family: Arial, sans-serif;
  font-weight: bold;
  border-radius: 30px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 16px;
  text-transform: uppercase;
}

input[type="text"],
input[type="email"],
input[type="tel"],
.message,
select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #222; /* Input field background color */
  color: #fff;
}

textarea {
  background-color: #222;
}

select {
  cursor: pointer;
}

.file-input {
  display: none;
}

button {
  padding: 10px;
  margin-top: 20px;
  background-color: #D66A6A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
}

button:hover {
  background-color: #555;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .page-container {
    grid-template-columns: 1fr;
  }

  .content,
  .form-container {
    padding: 20px;
  }

  .form-container {
    max-width: 100%;
  }
}
