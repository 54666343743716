.faqWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.faqs {
  background-color: #1F1F1F;
  color: #fff;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  border-radius: 20px;
  width: 80%;
}

.faqs h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.faq-item {
  border-bottom: 1px solid #696969;
  padding: 10px 0;
  cursor: pointer;
}

.faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-title span {
  font-size: 30px;
  font-weight: bolder;
}

.add-icon {
  color: #D66A6A;
  transition: transform 1s ease;
}

.add-icon.open {
  transform: rotate(45deg);
}

.faq-content {
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #848282;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  opacity: 0;
}

.faq-content.show {
  height: auto;
  opacity: 1;
}
