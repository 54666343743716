.video {
    width: 1500px;
    height: auto;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
}

.landing {
    display: flex;
    align-items: center;
    height: 90vh;
}

.text {
    width: 45%;
    align-items: center;
    text-align: left;
}


.landing .text h1 {
    color: #fff;
    font-size: 90px;
}

.animationText {
    height: 50px;
    width: 600px;
}

.text h3 {
    color: #C2C2C2;
    margin: 0;
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 36px;
    text-align: left;
    overflow: hidden; 
    font-weight: bolder;
}

.landing .flags {
    display: flex;
    gap: 15px;
}

.landing .flags img {
    width: 50px;
}

.landing .actions {
    margin-top: 20px;
    display: flex;
    gap: 26px;
    align-items: center;
}

.landing .actions .flex {
    display: flex;
    align-items: center;
    gap: 10px;
}

.landing .actions .flex .arrow-right {
    background-color: #D66A6A;
    padding: 5px;
    border-radius: 50%;
    color: #000;
}

.landing .actions a {
    color: #C2C2C2;
    font-weight: bold;
    font-size: 20px;
}

@media (max-width: 768px) {
    .landing {
        flex-direction: column;
        margin-bottom: 50px;
        margin-left: 0px;
    }

    .text {
        width: 310px;
    }

    .landing .video {
        width: 360px;
        height: 360px;
    }


    .text h3 {
        font-size: 26px;
    }
}