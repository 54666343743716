.features-section {
    margin-bottom: 100px;
}

/* Base styles for text */
.features-section .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: fit-content;
    margin: 0 auto 20px;
    text-align: center;
    font-weight: bold;
}

.features-section .text h1 {
    color: #fff;
    margin: 0;
    font-size: 55px;
}

.features-section .text span, .text p {
    color: #C2C2C2;
    margin: 0;
    font-weight: bold;
    font-size: 25px;
}

.features-section .text a {
    color: #D66A6A;
    font-size: 25px;
}

.features-section .text img {
    width: 60px;
}


@media (max-width: 768px) {
    .features-section .text {
        margin-bottom: 10px;
    }

    .features-section .text h1 {
        font-size: 28px;
    }

    .features-section .text img {
        width: 25px;
    }
}

@media (max-width: 480px) {
    .features-section .text h1 {
        font-size: 24px;
    }

    .features-section .text img {
        width: 20px;
    }
}
