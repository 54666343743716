.how-it-works {
  background-color: #e0e0e0;
  text-align: center;
}

.box {
  width: 100vw!important;
  height: 100vh;
  color: #fff;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  position: sticky;
  display: flex;
  top: 0;
  box-shadow: 20px 10px 100px rgba(0, 0, 0, 1.0);
  transition: 1.0s ease-in-out
}

.box-inner {
  text-align: center;
  width: 80%;
}

.box h1 {
  font-size: 60px;
}

.how-it-works .box-3 {
  background: radial-gradient(circle at center bottom, #C5CBD3, #C5CBD3 60%, #C5CBD3 80%, #AAB1B9);
}

.how-it-works .box-2 {
  background: radial-gradient(circle at center bottom, #CE9B9F, #CE9B9F 60%, #CE9B9F 80%, #B77A7D);
}

.how-it-works .box-1 {
  background: radial-gradient(circle at center bottom, #D66A6A, #D66A6A 60%, #D66A6A 80%, #B55757);
}
