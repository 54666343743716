body {
  background: #292929;
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #fff;
}