.footer {
    background-color: #1D1D1D;
    color: white;
    padding: 40px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .icon {
    color: white;
    font-size: 23px;
    cursor: pointer;
  }
  
  .icon:hover {
    color: #ccc;
  }
  
  .links ul {
    display: flex;
    gap: 10px;
    margin-right: -110px;
    font-size: 20px;
  }
  
  .links a {
    color: white;
    text-decoration: none;
  }
  
  .links a:hover {
    text-decoration: underline;
  }
  
  .copyrights {
    font-size: 14px;
  }
  
  @media (max-width: 800px) {
    .footer {
        flex-direction: column;
        gap: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
  }


  @media (max-width: 400px) {
    .links {
        flex-direction: column;
        gap: 10px;
        margin-right: 0;
    }
  }
